import classNames from 'classnames';
import { forwardRef, useEffect, useMemo } from 'react';
import { ToolFooter } from '..';
// @ts-ignore
import { initSortable } from '@/pages/combinedWard/hook/useTimeline';
import styles from '../index.less';

interface ImageItemProps {
  edit?: boolean;
  move?: boolean;
  imageData: any[];
  dict: any;
  onDelete?: (val?: any) => void;
  onUpdate?: (value?: any) => void;
  onSort?: (val?: any) => void;
  onRela?: (id: string, ty: string) => void;
}

const ImageItem = forwardRef((props: ImageItemProps, ref: any) => {
  const { imageData, edit, move, dict, onDelete, onUpdate, onRela, onSort } = props;

  const memoized = useMemo(() => imageData, [imageData]);

  useEffect(() => {
    if (!move && edit && imageData.length > 1) {
      // console.log(move, edit);
      initSortable(ref, onSort, move || !edit);
    }
  }, [memoized, edit, move]);

  return (
    <div
      ref={ref}
      className={classNames(styles.card, styles['image-card'], styles['line-border-bottom'])}
      style={
        {
          '--lineHeight': '50%',
        } as any
      }
    >
      {memoized?.map((el, index) => (
        <div
          key={el?.id}
          id={JSON.stringify(el)}
          className={classNames(edit && !move && 'handle', styles.image)}
        >
          <div className={classNames(styles.name)}>
            <span className={classNames(styles.round)} />
            {el?.parts}
            {dict?.imageType?.[`${el?.imagesType}`]}：{el?.reportDiagnose || ''}
          </div>
          {el?.refContent && (
            <div className={classNames(styles['link-box'])}>
              <a onClick={() => onRela?.(el?.refId, el?.refType)}>({el?.refContent})</a>
            </div>
          )}
          <ToolFooter
            edit={edit}
            onEdit={(e: any) => {
              e.preventDefault();
              onUpdate?.(el);
            }}
            onDel={() => onDelete?.(el)}
          />
          {memoized.length - 1 > index ? <div style={{ height: 14 }} /> : null}
        </div>
      ))}
    </div>
  );
});

export default ImageItem;
