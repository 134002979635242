import { Form, FormItem } from '@/components/BaseForm';
import Label from '@/components/Label';
import Text from '@/components/Text';
import { useLocales } from '@/Locales';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import RecordImgUpload from '@/pages/patient/detail/components/RecordImgUpload';
import TabHeader, { TabHeaderInput } from '@/pages/patient/detail/components/TabHeader';
import { addPatientDrugRecords, updatePatientDrugRecords } from '@/services/patient';
import { Form as AntdForm, DatePicker, Input } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

const MedicalRecordsItem = (props: any) => {
  const { data } = props;
  const [form] = AntdForm.useForm();
  const [readOnly, setReadOnly] = useState(false);
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const [patientInfoStatus, setPatientInfoStatus] = useRecoilState(PatientInfoStatus);
  const { t } = useLocales();

  useEffect(() => {
    setReadOnly(!!data?.id);
  }, []);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    return () => {
      // 组件销毁时，如果是编辑状态，把全局的可编辑状态还原
      if (isEditing) {
        setPatientInfoStatus({
          edit: true,
        });
      }
    };
  }, [isEditing]);

  const handleChangeReadOnly = () => {
    const newReadOnly = !readOnly;
    setReadOnly(newReadOnly);
    setPatientInfoStatus({
      edit: newReadOnly,
    });
    setIsEditing(!newReadOnly);
    if (newReadOnly) {
      patientInfo.update();
    }
    form.resetFields();
  };

  const handleSubmit = () => {
    form.validateFields().then((formValue) => {
      const { medicationTime, ...otherFormValue } = formValue;
      const params = {
        ...otherFormValue,
        patientId: patientInfo.patient.id,
        startMedicationTime: medicationTime?.[0] ? medicationTime[0].format('YYYY-MM-DD') : '',
        endMedicationTime: medicationTime?.[1] ? medicationTime[1].format('YYYY-MM-DD') : '',
      };
      const api = formValue?.id ? updatePatientDrugRecords : addPatientDrugRecords;
      api(params).then(() => {
        patientInfo.update();
        handleChangeReadOnly();
      });
    });
  };
  const action = readOnly ? (
    <a onClick={handleChangeReadOnly}>
      <Text>编辑</Text>
    </a>
  ) : (
    <>
      <a onClick={handleChangeReadOnly}>
        <Text>取消</Text>
      </a>
      <a onClick={handleSubmit}>
        <Text>保存</Text>
      </a>
    </>
  );
  const mapImage = (urls: string) => {
    return <RecordImgUpload value={urls} disabled />;
  };
  const formValue = {
    ...data,
    medicationTime:
      data.startMedicationTime && data.endMedicationTime
        ? [data.startMedicationTime, data.endMedicationTime].map(dayjs)
        : null,
  };
  const getDateText = (item: any) => {
    return item ? item.map((date: any) => date.format('YYYY-MM-DD')).join('至') : '--';
  };
  return (
    <Form
      className="record-form"
      form={form}
      readOnly={readOnly}
      initialValues={formValue}
      layout={readOnly ? 'horizontal' : 'vertical'}
    >
      <FormItem name="id" hidden>
        <Input />
      </FormItem>
      <FormItem
        noStyle
        transformText={(text) => (
          <TabHeader
            label={<Text>医嘱时间</Text>}
            title={dayjs(text).format('YYYY-MM-DD')}
            action={action}
          />
        )}
        name="recordTime"
        span={24}
      >
        <TabHeaderInput label={<Text>医嘱时间</Text>} action={action} />
      </FormItem>
      <FormItem name="imagePath" transformText={mapImage}>
        <RecordImgUpload fileType="drugRecord" />
      </FormItem>
      <FormItem label={<Label>用药时期</Label>} name="medicationTime" transformText={getDateText}>
        <DatePicker.RangePicker
          placeholder={[t('请选择用药开始时间'), t('请选择用药结束时间')]}
          style={{ width: 400 }}
        />
      </FormItem>
      <FormItem label={<Label>用药方案</Label>} name="drugPlan">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
      <FormItem label={<Label>不良反应</Label>} name="adverseReactions">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
      <FormItem label={<Label>治疗效果</Label>} name="treatmentEffect">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
    </Form>
  );
};

export default MedicalRecordsItem;
