import { useLocales } from '@/Locales';
import {
  httpImageProcessBatchDelete,
  httpImageProcessClassify,
  httpImageProcessDeleteTask,
  httpImageProcessQueryPatientTask,
  httpImageProcessQueryTaskDetails,
  httpImageProcessSaveTask,
  httpImageProcessUpdateClassify,
} from '@/services/imageProcess';
import { CompressFilesProps } from '@/utils/picControler/ImageBase';
import { message } from '@sinohealth/butterfly-ui-components';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// @ts-ignore

class TaskQueue {
  private queue: (() => Promise<void>)[] = [];

  private processing: boolean = false;

  constructor() {
    this.removeAt = this.removeAt.bind(this);
  }

  enqueue(task: () => Promise<void>) {
    this.queue.push(task);
  }

  removeAt(index: number): void {
    console.log('Current queue:', this.queue);
    if (index < 0 || index >= this.queue.length) {
      console.log('Index out of bounds');
      return;
    }
    this.queue.splice(index, 1); // 移除指定索引的任务
    console.log('Queue after removal:', this.queue);
  }

  async processNext(): Promise<void> {
    if (this.processing) return;
    this.processing = true;
    console.log('Processing next task, queue length:', this.queue.length);
    if (this.queue.length === 0) {
      this.processing = false;
      return;
    }

    const currentTask = this.queue.shift(); // 从队列中移除并返回第一个任务
    if (currentTask) {
      try {
        await currentTask(); // 执行当前任务
      } catch (error) {
        console.error('Error executing task:', error);
      }
      await this.processNext(); // 递归地处理下一个任务
    }

    this.processing = false;
  }

  async processAll(): Promise<void> {
    console.log('Starting to process all tasks, initial queue length:', this.queue.length);
    while (this.queue.length > 0) {
      // eslint-disable-next-line no-await-in-loop
      await this.processNext();
      console.log('Processed a task, remaining queue length:', this.queue.length);
    }
  }
}

/**
 * @description: 处理图片任务
 * @author: KaifengLi
 * @version: v2.27.0
 * @Date: 2024-11-19 14:59:28
 */
export const useProcessFile = (scene = 'recognition', fileType = '') => {
  const [params] = useSearchParams();
  const patientId: string = params.get('patientId') || ''; // 智能识别的患者id
  const id = params.get('id') || ''; // 患者详情的患者id
  const { t } = useLocales();

  // const taskQueue = new TaskQueue();
  const [taskIndex, setTaskIndex] = useState(0);
  const [taskProcessing, setTaskProcessing] = useState(false);
  const [processDetail, setProcessDetail] = useState<any>();
  const [processSucc, setProcessSucc] = useState(false);
  const [uploadFiles, setUploadFiles] = useState<CompressFilesProps[]>([]);

  const isMounted = useRef(true);
  let intervalId: any = null;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // 定义处理单个文件的异步函数
  const processFile = async (file: CompressFilesProps) => {
    try {
      setTaskProcessing(true);
      console.log(`Processing image: ${file?.file?.name}`);
      const formDate = new FormData();
      formDate.append('file', file?.file);
      formDate.append('patientId', patientId || id);
      formDate.append('scene', scene);
      if (fileType) {
        formDate.append('fileType', fileType);
      }
      setProcessDetail(undefined);
      const task: any = await httpImageProcessSaveTask(formDate);
      // 状态(in_processing-处理中,completed-已完成，fail-失败，unqualified-不合格)

      await httpImageProcessQueryTaskDetailsReq(task?.id);
      // if (task?.id && task?.status === 'in_processing') {
      //   await httpImageProcessQueryTaskDetailsReq(task.id);
      // } else if (scene === 'report') {
      //   await httpImageProcessQueryTaskDetailsReq(task.id);
      // } else {
      //   setProcessSucc(true);
      // }
      if (task?.isRepeat) {
        message.success(`文件【${file.file.name}】已上传`);
      }
      console.log(`Finished processing image: ${file?.file?.name}`);
      setProcessSucc(false);
      setTaskProcessing(false);
      if (isMounted.current) {
        setUploadFiles((prev) => prev.filter((item) => item?.file?.name !== file?.file?.name));
      }
    } catch (error) {
      console.log('Error processing file:', error);
      if (isMounted.current) {
        setTaskProcessing(false);
        intervalId && clearInterval(intervalId);
        removeFileFromQueue(0);
      }
    }
  };

  /** 查询图片处理任务详情 */
  const httpImageProcessQueryTaskDetailsReq = (taskId: string) => {
    return new Promise((resolve, reject) => {
      intervalId = setInterval(async () => {
        try {
          const res: any = await httpImageProcessQueryTaskDetails({ taskId });
          // console.log(isMounted.current);
          const { status } = res;
          if (status !== 'unqualified') {
            setProcessDetail(res);
          }
          // 不是处理中的时候，清除定时器
          if (status !== 'in_processing') {
            clearInterval(intervalId); // 停止轮询
            // 有id时候代表是在患者详情当个上传，需要返回数据
            setProcessSucc(true);
            if (status === 'completed') {
              message.success('图片处理成功');
            }
            if (status === 'unqualified') {
              message.error('图片不清晰，请重新上传');
            }
            if (status === 'fail') {
              message.error(res?.failReason || '图片处理失败');
            }
            resolve(true); // 处理状态不等于 in_processing 时，执行下一步
          }
        } catch (error) {
          clearInterval(intervalId); // 发生错误时停止轮询
          if (isMounted.current) {
            reject(error); // 抛出错误
          }
        }
      }, 3000);
    });
  };

  // 添加文件到队列
  const addFileToQueue = (files: CompressFilesProps[]) => {
    // files.forEach((image) => {
    //   taskQueue.enqueue(() => processFile(image));
    // });
    setTaskIndex(0);
    setUploadFiles((prev) => [...prev, ...files]);
    // taskQueue.processAll(); // 开始处理所有任务
  };

  // 根据索引移除队列成员
  const removeFileFromQueue = (index: number) => {
    // taskQueue.removeAt(index);
    const file = [...uploadFiles];
    file.splice(index, 1); // 移除指定索引的任务
    if (isMounted.current) {
      setUploadFiles(file);
    }
  };

  useEffect(() => {
    // console.log('uploadFiles', uploadFiles);
    if (taskProcessing) return;
    if (uploadFiles.length > 0) {
      processFile(uploadFiles[taskIndex]);
    }
  }, [uploadFiles, taskIndex]);

  return {
    uploadFiles,
    setUploadFiles,
    addFileToQueue,
    removeFileFromQueue,
    processDetail,
    processSucc,
    setProcessDetail,
  };
};

/**
 * @description: 查询图片分类列表
 * @author: KaifengLi
 * @version: v2.27.0
 * @Date: 2024-11-20 13:39:59
 */
export const useProcessClassify = () => {
  const [params] = useSearchParams();
  const patientId: string = params.get('patientId') || '';

  const [reloadClassify, setReloadClassify] = useState(false);
  // 加载成功，用于控制绑定ocr任务
  const [loadClassifySucc, setLoadClassifySucc] = useState(false);
  const [unqualiFied, setUnqualiFied] = useState<any[]>([]);
  const [classifyColumns, setClassifyColumns] = useState<any>([]);
  const [completedFileUrls, setCompletedFileUrls] = useState<string[]>([]);

  const httpImageProcessClassifyReq = async () => {
    try {
      const res: any = await httpImageProcessClassify({ patientId });
      // console.log(res);
      setReloadClassify(false);
      const {
        allCompletedFileUrlList,
        dischargeRecordList,
        drugRecordList,
        geneticTestingList,
        imageReportList,
        inspectionReportList,
        medicalRecordList,
        otherInformationList,
        pathologyReportList,
        surgicalRecordList,
        unqualifiedList,
      } = res;
      setUnqualiFied(unqualifiedList || []);
      setCompletedFileUrls(allCompletedFileUrlList || []);
      setClassifyColumns([
        {
          label: '入院记录',
          value: 'medicalRecord',
          items: medicalRecordList,
        },
        {
          label: '出院记录',
          value: 'hospitalDischargeRecord',
          items: dischargeRecordList,
        },
        {
          label: '影像报告',
          value: 'imageReport',
          items: imageReportList,
        },
        {
          label: '手术信息',
          value: 'surgicalRecord',
          items: surgicalRecordList,
        },
        {
          label: '病理报告',
          value: 'pathologyReport',
          items: pathologyReportList,
        },
        {
          label: '检验报告',
          value: 'inspectionReport',
          items: inspectionReportList,
        },
        {
          label: '基因检测',
          value: 'geneticTesting',
          items: geneticTestingList,
        },
        {
          label: '用药记录',
          value: 'drugRecord',
          items: drugRecordList,
        },
        {
          label: '参考文献',
          value: 'otherInformation',
          items: otherInformationList,
        },
      ]);
      setLoadClassifySucc(true);
    } catch (err) {
      console.log(err);
    } finally {
      setReloadClassify(false);
      setLoadClassifySucc(false);
    }
  };

  useEffect(() => {
    patientId && httpImageProcessClassifyReq();
  }, [patientId]);

  useEffect(() => {
    // console.log({ reloadClassify });
    reloadClassify && httpImageProcessClassifyReq();
  }, [reloadClassify]);

  return {
    unqualiFied,
    classifyColumns,
    loadClassifySucc,
    setLoadClassifySucc,
    setClassifyColumns,
    setReloadClassify,
    completedFileUrls,
  };
};

export const classifyData = [
  {
    label: '入院记录',
    value: 'medicalRecord',
  },
  {
    label: '出院记录',
    value: 'hospitalDischargeRecord',
  },
  {
    label: '影像报告',
    value: 'imageReport',
  },
  {
    label: '手术信息',
    value: 'surgicalRecord',
  },
  {
    label: '病理报告',
    value: 'pathologyReport',
  },
  {
    label: '检验报告',
    value: 'inspectionReport',
  },
  {
    label: '基因检测',
    value: 'geneticTesting',
  },
  {
    label: '用药记录',
    value: 'drugRecord',
  },
  {
    label: '参考文献',
    value: 'otherInformation',
  },
];

/**
 * @description: 更新图片分类
 * @author: KaifengLi
 * @version: v2.27.0
 * @Date: 2024-11-20 14:12:27
 */
export const useUpdateClassify = () => {
  const [updateParams, setUpdateParams] = useState<any>();
  const [uploadSucc, setUploadSucc] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const httpImageProcessUpdateClassifyReq = async () => {
    try {
      setLoadingUpload(true);
      const res = await httpImageProcessUpdateClassify(updateParams);
      // console.log(res);
      setUploadSucc(true);
    } catch (error) {
      console.log(error);
    } finally {
      setUploadSucc(false);
      setLoadingUpload(false);
    }
  };

  useEffect(() => {
    updateParams && httpImageProcessUpdateClassifyReq();
  }, [updateParams]);

  return { setUpdateParams, uploadSucc, setLoadingUpload, loadingUpload };
};

/**
 * @description: 删除图片处理任务
 * @author: KaifengLi
 * @version: v2.27.0
 * @Date: 2024-11-20 14:14:37
 */
export const useDeleteTask = () => {
  const [deleteParams, setDeleteParams] = useState<any>();
  const [deleteSucc, setDeleteSucc] = useState(false);

  const httpImageProcessDeleteTaskReq = async () => {
    try {
      const res = await httpImageProcessDeleteTask(deleteParams);
      // console.log(res);
      setDeleteSucc(true);
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteSucc(false);
      setDeleteParams(undefined);
    }
  };

  useEffect(() => {
    deleteParams && httpImageProcessDeleteTaskReq();
  }, [deleteParams]);

  return { setDeleteParams, deleteSucc };
};

/**
 * @description: 批量删除识别图片
 * @author: KaifengLi
 * @version: v2.27.0
 * @Date: 2024-11-21 14:06:22
 */
export const useBatchDeleteTask = () => {
  const [batchParams, setBatchParams] = useState<any>();
  const [batchDeleteSucc, setBatchDeleteSucc] = useState(false);

  const httpImageProcessBatchDeleteReq = async () => {
    try {
      const res = await httpImageProcessBatchDelete(batchParams);
      // console.log(res);
      setBatchDeleteSucc(true);
    } catch (error) {
      console.log(error);
    } finally {
      setBatchDeleteSucc(false);
      setBatchParams(undefined);
    }
  };

  useEffect(() => {
    batchParams && httpImageProcessBatchDeleteReq();
  }, [batchParams]);

  return { setBatchParams, batchDeleteSucc };
};

/**
 * @description: 批量处理文件
 * @author: KaifengLi
 * @param {*} scene 场景 recognition-智能识别，report-报告上传
 * @param {*} fileType 文件类型
 * @version: v2.27.0
 * @Date: 2024-11-27 16:16:23
 */
export const useQueryPatientTask = (param?: any) => {
  const { scene = 'recognition', fileType = '', callback } = param;
  const [params] = useSearchParams();
  const patientId: string = params.get('patientId') || '';
  const id = params.get('id') || ''; // 患者详情的患者id
  const [queryPatientTask, setQueryPatientTask] = useState<any>({
    patientId,
    scene: 'recognition',
    statusList: ['in_processing'],
  });

  const [saveLoading, setSaveLoading] = useState(false);
  const [patientTasks, setPatientTasks] = useState<any[]>([]);
  let taskIds: any[] = [];
  const [uploadFiles, setUploadFiles] = useState<CompressFilesProps[]>([]);
  let intervalId: any = null;

  const httpImageProcessSaveTaskReq = async (file: CompressFilesProps, index: number) => {
    try {
      const formDate = new FormData();
      formDate.append('file', file?.file);
      formDate.append('patientId', patientId || id);
      formDate.append('scene', scene);
      if (fileType) {
        formDate.append('fileType', fileType);
      }
      // intervalId && clearInterval(intervalId); // 清除之前的定时器
      const res: any = await httpImageProcessSaveTask(formDate);
      taskIds.push(res.id);
      if (res?.isRepeat) {
        message.success(`文件【${file.file.name}】已上传`);
        // 直接刷新
        if (callback) {
          // 刷新分类列表
          callback();
        }
        setSaveLoading(false);
      } else if (!intervalId && index === uploadFiles.length - 1) {
        // 图片都保存
        httpImageProcessQueryPatientTaskReq();
        const timer = setTimeout(() => {
          setSaveLoading(false);
          setUploadFiles([]);
          clearTimeout(timer);
        }, 0);
        // intervalId = 998;
      }
    } catch (err) {
      console.log(err);
    }
  };

  /** 查询图片处理任务详情 */
  const httpImageProcessQueryTaskDetailsReq = async (taskId: string) => {
    try {
      const res: any = await httpImageProcessQueryTaskDetails({ taskId });
      // console.log(isMounted.current);
      const { status } = res;
      // 不是处理中的时候，清除定时器
      if (status !== 'in_processing') {
        if (status === 'completed') {
          message.success('图片处理成功');
        }
        if (status === 'unqualified') {
          message.error('图片不清晰，请重新上传');
        }
        if (status === 'fail') {
          message.error(res?.failReason || '图片处理失败');
        }
      }
      if (callback) {
        // 刷新分类列表
        callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const interFn = () => {
    intervalId = setInterval(async () => {
      httpImageProcessQueryPatientTaskReq();
    }, 2000);
  };

  /** 查询患者图片处理任务编号 */
  const httpImageProcessQueryPatientTaskReq = async () => {
    try {
      const res: any = await httpImageProcessQueryPatientTask({
        patientId,
        scene: 'recognition',
        statusList: ['in_processing'],
      });
      const { taskList = [] } = res || {};
      // console.log({ res, taskIds });
      // 查询详情接口，做逻辑处理
      if (taskIds.length > 0 && taskList.length !== taskIds.length) {
        // 正在执行的任务id
        const ids = taskList?.map((item: any) => item.id) || [];
        // 已经执行成功的id
        const completedFiles = taskIds.filter((el: any) => !ids?.includes(el.id));
        // const completeIds = completedFiles?.map((el: any) => el?.id);

        // console.log({ completedFiles });
        // console.log(res);
        if (!completedFiles.length) return;
        // 调取详情接口，做逻辑处理
        completedFiles.forEach((item: any) => {
          httpImageProcessQueryTaskDetailsReq(item);
        });
        // 过滤掉已经执行成功的id
        taskIds = completedFiles.length
          ? taskIds?.filter((el: any) => !completedFiles.includes(el))
          : []; // 任务为空，直接置空
        console.log({ taskIds });
      }

      if (taskList?.length === 0) {
        intervalId && clearInterval(intervalId); // 没有任务,清除定时器
      } else {
        // 先清除定时器
        intervalId && clearInterval(intervalId);
        interFn();
        taskIds = taskList?.map((el: any) => el.id);
      }
      setPatientTasks(taskList);
    } catch (err) {
      console.log(err);
      intervalId && clearInterval(intervalId); // 发生错误时停止轮询
    }
  };

  useEffect(() => {
    if (uploadFiles.length) {
      intervalId && clearInterval(intervalId);
      setSaveLoading(true);
      uploadFiles.forEach((file, index) => {
        httpImageProcessSaveTaskReq(file, index);
      });
    }
  }, [uploadFiles]);

  useEffect(() => {
    // 默认查询一次
    httpImageProcessQueryPatientTaskReq();
  }, []);

  return { setQueryPatientTask, setUploadFiles, patientTasks, saveLoading };
};
