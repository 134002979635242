/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import { ProgressHintInfo } from '@/utils/picControler/ImageBase';
import { selector } from 'recoil';
import { compressOptionAtom, imageListAtom } from './smallerAtoms';

export const isCropModeSelector = selector<boolean>({
  key: 'isCropModeSelector',
  get: ({ get }) => {
    const option = get(compressOptionAtom);
    const resize = option.resize;
    return (
      (resize.method === 'setCropRatio' &&
        resize.cropWidthRatio &&
        resize.cropHeightRatio &&
        resize.cropWidthRatio > 0 &&
        resize.cropHeightRatio > 0) ||
      (resize.method === 'setCropSize' &&
        resize.cropWidthSize &&
        resize.cropHeightSize &&
        resize.cropWidthSize > 0 &&
        resize.cropHeightSize > 0) ||
      false
    ); // 确保返回值不会是 undefined
  },
});

export const hasTaskRunningSelector = selector<boolean>({
  key: 'hasTaskRunningSelector',
  get: ({ get }) => {
    const list = get(imageListAtom);
    for (const [_, value] of Array.from(list)) {
      if (!value.preview || !value.compress) {
        return true;
      }
    }
    return false;
  },
});

export const progressHintInfoSelector = selector<ProgressHintInfo>({
  key: 'progressHintInfoSelector',
  get: ({ get }) => {
    const list = get(imageListAtom);
    const totalNum = list.size;
    let loadedNum = 0;
    let originSize = 0;
    let outputSize = 0;

    for (const [_, info] of Array.from(list)) {
      originSize += info.blob.size;
      if (info.compress) {
        loadedNum++;
        outputSize += info.compress.blob.size;
      }
    }
    const percent = Math.ceil((loadedNum * 100) / totalNum);
    const originRate = ((outputSize - originSize) * 100) / originSize;
    const rate = Number(Math.abs(originRate).toFixed(2));

    return {
      totalNum,
      loadedNum,
      originSize,
      outputSize,
      percent,
      rate,
    };
  },
});
