import { Form, FormItem } from '@/components/BaseForm';
import Label from '@/components/Label';
import { useLocales } from '@/Locales';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import RecordImgUpload from '@/pages/patient/detail/components/RecordImgUpload';
import TabHeader, { TabHeaderInput } from '@/pages/patient/detail/components/TabHeader';
import { createOrUpdateDischarge } from '@/services/patient';
import { Form as AntdForm, DatePicker, Input } from '@sinohealth/butterfly-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

const MedicalRecordsItem = (props: any) => {
  const { data } = props;
  const [form] = AntdForm.useForm();
  const [readOnly, setReadOnly] = useState(false);
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const [patientInfoStatus, setPatientInfoStatus] = useRecoilState(PatientInfoStatus);
  const { t } = useLocales();

  useEffect(() => {
    setReadOnly(!!data?.id);
  }, []);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    return () => {
      // 组件销毁时，如果是编辑状态，把全局的可编辑状态还原
      if (isEditing) {
        setPatientInfoStatus({
          edit: true,
        });
      }
    };
  }, [isEditing]);

  const handleChangeReadOnly = () => {
    const newReadOnly = !readOnly;
    setReadOnly(newReadOnly);
    setPatientInfoStatus({
      edit: newReadOnly,
    });
    setIsEditing(!newReadOnly);
    if (newReadOnly) {
      patientInfo.update();
    }
    form.resetFields();
  };

  const handleCancel = () => {
    patientInfo.update();
    handleChangeReadOnly();
  };

  const handleSubmit = () => {
    form.validateFields().then((formValue) => {
      const params = {
        ...formValue,
        admitTime: formValue.admitTime || '',
        patientId: patientInfo.patient.id,
      };
      createOrUpdateDischarge(params).then(() => {
        patientInfo.update();
        handleChangeReadOnly();
      });
    });
  };
  const action = readOnly ? (
    <a onClick={handleChangeReadOnly}>{t('编辑')}</a>
  ) : (
    <>
      <a onClick={handleCancel}>{t('取消')}</a>
      <a onClick={handleSubmit}>{t('保存')}</a>
    </>
  );
  const mapImage = (urls: string) => {
    return <RecordImgUpload value={urls} disabled />;
  };
  return (
    <Form
      className="record-form"
      form={form}
      readOnly={readOnly}
      initialValues={data}
      layout={readOnly ? 'horizontal' : 'vertical'}
    >
      <FormItem name="id" hidden>
        <Input />
      </FormItem>
      <FormItem
        noStyle
        transformText={(text) => (
          <TabHeader
            label={t('出院时间')}
            title={moment(text).format('YYYY-MM-DD')}
            action={action}
          />
        )}
        name="dischargeTime"
        span={24}
      >
        <TabHeaderInput label={t('出院时间')} action={action} />
      </FormItem>
      <FormItem name="imagePath" transformText={mapImage}>
        <RecordImgUpload fileType="hospitalDischargeRecord" />
      </FormItem>
      <FormItem
        label={<Label>入院时间</Label>}
        name="admitTime"
        isDate
        transformText={(text) => (text ? moment(text).format('YYYY-MM-DD') : '--')}
      >
        <DatePicker style={{ width: 320 }} />
      </FormItem>
      <FormItem label={<Label>入院情况</Label>} name="admitDescription">
        <Input.TextArea maxLength={5000} showCount autoSize={{ minRows: 6, maxRows: 6 }} />
      </FormItem>
      <FormItem label={<Label>治疗经过</Label>} name="treatmentCourse">
        <Input.TextArea maxLength={5000} showCount autoSize={{ minRows: 6, maxRows: 6 }} />
      </FormItem>
      <FormItem label={<Label>出院情况</Label>} name="dischargeDescription">
        <Input.TextArea maxLength={5000} showCount autoSize={{ minRows: 6, maxRows: 6 }} />
      </FormItem>
      <FormItem label={<Label>出院医嘱</Label>} name="dischargeAdvice">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
    </Form>
  );
};

export default MedicalRecordsItem;
