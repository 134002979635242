/* eslint-disable react/no-array-index-key */
import { initSortable } from '@/pages/combinedWard/hook/useTimeline';
import classNames from 'classnames';
import { forwardRef, useEffect, useMemo } from 'react';
import { ToolFooter } from '..';
import styles from '../index.less';

interface OtherProps {
  edit?: boolean;
  move?: boolean;
  otherData: any[];
  cardHeight: any;
  borderRadius?: number;
  onDelete?: (val?: any) => void;
  onUpdate?: (value?: any) => void;
  onSort?: (val?: any) => void;
  dict: any;
  onRela?: (id: string, ty: string) => void;
}

const Other = forwardRef((props: OtherProps, ref: any) => {
  const { dict, move, onUpdate, edit, cardHeight, onDelete, onRela, otherData, onSort } = props;

  const memoized = useMemo(() => otherData, [otherData]);

  useEffect(() => {
    if (!move && edit && otherData.length > 1) {
      // console.log(move, edit);
      initSortable(ref, onSort, move || !edit);
    }
  }, [memoized, edit, move]);

  return (
    <div
      ref={ref}
      className={classNames(styles.card, styles['other-card'], styles['line-border-bottom'])}
      style={
        {
          '--lineHeight': `${
            cardHeight.imageHeight / 2 +
            cardHeight.otherHeight / 2 +
            cardHeight.inspectHeight / 2 +
            6
          }px`,
          borderRadius: props?.borderRadius,
        } as any
      }
    >
      {memoized?.map((el, index) => (
        <div
          key={`${el?.id}_${index}`}
          id={JSON.stringify(el)}
          className={classNames(edit && !move && 'handle', styles.other)}
        >
          <div className={classNames(styles.name)}>
            <span className={classNames(styles.round)} />
            {dict?.timelineOtherSelect?.[el?.nodeType]}：{el?.diagnosis || ''}{' '}
            {el?.description || ''} {el?.conclusion || ''}
          </div>
          {el?.refContent && (
            <div className={classNames(styles['link-box'])}>
              <a onClick={() => onRela?.(el?.refId, el?.refType)}>({el?.refContent})</a>
            </div>
          )}
          <ToolFooter
            edit={edit}
            onEdit={(e: any) => {
              e.preventDefault();
              onUpdate?.(el);
            }}
            onDel={() => onDelete?.(el)}
          />
          {memoized.length - 1 > index ? <div style={{ height: 14 }} /> : null}
        </div>
      ))}
    </div>
  );
});

export default Other;
