import { Route, Routes } from 'react-router-dom';
// import { usePermission } from '@/common/hooks';
import routerConfig from '@/config/router';
import KeepAlive from 'react-activation';

type routerConfigProps = {
  path: string;
  element: any;
  children?: routerConfigProps[];
};

const renderRoute = (routerConfigData: any[]) =>
  routerConfigData.map(({ children, ...routeProps }) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    if (routeProps.cached) {
      return (
        <Route
          {...routeProps}
          key={routeProps.path}
          element={
            <KeepAlive cacheKey={routeProps.path} name={routeProps.path}>
              {routeProps.element}
            </KeepAlive>
          }
        >
          {children && renderRoute(children)}
        </Route>
      );
    }
    return (
      <Route {...routeProps} key={routeProps.path}>
        {children && renderRoute(children)}
      </Route>
    );
  });

export const RenderRoutes = () => {
  return <Routes>{renderRoute(routerConfig)}</Routes>;
};

export default RenderRoutes;
