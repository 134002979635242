/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import { initSortable } from '@/pages/combinedWard/hook/useTimeline';
import classNames from 'classnames';
import { forwardRef, useEffect, useMemo } from 'react';
import { ToolFooter } from '..';
import styles from '../index.less';

interface TreatmentProps {
  edit?: boolean;
  move?: boolean;
  treatmentData: any[];
  dict: any;
  onDelete?: (val?: any) => void;
  onUpdate?: (value?: any) => void;
  onSort?: (val?: any) => void;
  onRela?: (id: string, ty: string) => void;
}

const Treatment = forwardRef((props: TreatmentProps, ref: any) => {
  const { dict, move, treatmentData, edit, onUpdate, onDelete, onRela, onSort } = props;

  const memoized = useMemo(() => treatmentData, [treatmentData]);

  useEffect(() => {
    if (!move && edit && treatmentData.length > 1) {
      // console.log(move, edit);
      initSortable(ref, onSort, move || !edit);
    }
  }, [memoized, edit, move]);

  return (
    <div
      ref={ref}
      className={classNames(styles.card, styles['treatment-card'], styles['line-border-top'])}
      style={
        {
          '--lineHeight': '50%',
          marginBottom: 0,
        } as any
      }
    >
      {memoized?.map((el, index) => (
        <div
          key={`${el?.id}_${index}`}
          id={JSON.stringify(el)}
          className={classNames(edit && !move && 'handle', styles.treatment)}
        >
          <div className={classNames(styles.name)}>
            <span className={classNames(styles.round)} />
            {dict?.nodeTypeSelect?.[el?.nodeType]}：{el?.drugPlan || ''}
            {el?.surgeryName || ''}
            {el?.description || ''}
          </div>
          {el?.refContent && (
            <div className={classNames(styles['link-box'])}>
              <a onClick={() => onRela?.(el?.refId, el?.refType)}>({el?.refContent})</a>
            </div>
          )}
          <ToolFooter
            edit={edit}
            onEdit={(e: any) => {
              e.preventDefault();
              onUpdate?.(el);
            }}
            onDel={() => onDelete?.(el)}
          />
          {memoized.length - 1 > index ? <div style={{ height: 14 }} /> : null}
        </div>
      ))}
    </div>
  );
});

export default Treatment;
