import { Form, FormItem } from '@/components/BaseForm';
import Label from '@/components/Label';
import { useLocales } from '@/Locales';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import RecordImgUpload from '@/pages/patient/detail/components/RecordImgUpload';
import TabHeader, { TabHeaderInput } from '@/pages/patient/detail/components/TabHeader';
import { addPatientMedical, updatePatientMedical } from '@/services/patient';
import { Form as AntdForm, Input } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

const requiredRule = { required: true, message: '该字段为必填项' };
const MedicalRecordsItem = (props: any) => {
  const { data } = props;
  const [form] = AntdForm.useForm();
  const [readOnly, setReadOnly] = useState(false);
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const [patientInfoStatus, setPatientInfoStatus] = useRecoilState(PatientInfoStatus);
  const { t } = useLocales();

  useEffect(() => {
    setReadOnly(!!data?.id);
  }, []);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    return () => {
      // 组件销毁时，如果是编辑状态，把全局的可编辑状态还原
      if (isEditing) {
        setPatientInfoStatus({
          edit: true,
        });
      }
    };
  }, [isEditing]);
  const handleChangeReadOnly = () => {
    const newReadOnly = !readOnly;
    setReadOnly(newReadOnly);
    setIsEditing(!newReadOnly);
    setPatientInfoStatus({
      edit: newReadOnly,
    });
    if (newReadOnly) {
      patientInfo.update();
    }
    form.resetFields();
  };
  const handleSubmit = () => {
    form.validateFields().then((formValue) => {
      const params = {
        ...formValue,
        patientId: patientInfo.patient.id,
      };
      const api = formValue?.id ? updatePatientMedical : addPatientMedical;
      api(params).then(() => {
        patientInfo.update();
        handleChangeReadOnly();
      });
    });
  };
  const action = readOnly ? (
    <a onClick={handleChangeReadOnly}>{t('编辑')}</a>
  ) : (
    <>
      <a onClick={handleChangeReadOnly}>{t('取消')}</a>
      <a onClick={handleSubmit}>{t('保存')}</a>
    </>
  );
  const mapImage = (urls: string) => {
    return <RecordImgUpload value={urls} disabled />;
  };
  return (
    <Form
      className="record-form"
      form={form}
      readOnly={readOnly}
      initialValues={data}
      layout={readOnly ? 'horizontal' : 'vertical'}
    >
      <FormItem name="id" hidden>
        <Input />
      </FormItem>
      <FormItem
        noStyle
        transformText={(text) => (
          <TabHeader
            disabled
            label={t('入院时间')}
            title={dayjs(text || '').format('YYYY-MM-DD')}
            action={action}
          />
        )}
        name="recordTime"
        span={24}
      >
        <TabHeaderInput label={t('入院时间')} action={action} />
      </FormItem>
      <FormItem name="imagePath" transformText={mapImage}>
        <RecordImgUpload fileType="medicalRecord" />
      </FormItem>
      <FormItem label={<Label>主诉</Label>} name="chiefComplaint">
        <Input maxLength={500} showCount />
      </FormItem>
      <FormItem label={<Label>现病史</Label>} name="presentIllness">
        <Input.TextArea maxLength={5000} showCount autoSize={{ minRows: 6, maxRows: 6 }} />
      </FormItem>
      <FormItem label={<Label>既往史</Label>} name="pastHistory">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
      <FormItem label={<Label>体格检查</Label>} name="physicalCheck">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
      <FormItem
        label={
          <>
            <Label>其他</Label>
            {!readOnly && (
              <span style={{ fontSize: '14px', color: '#00000073' }}>
                （{t('可输入过敏史、个人史、月经史、婚育史、家族史')}）
              </span>
            )}
          </>
        }
        name="other"
      >
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
    </Form>
  );
};

export default MedicalRecordsItem;
