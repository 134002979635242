/* eslint-disable no-param-reassign */
import { useRecoilState, useRecoilValue } from 'recoil';
// import { createMessageData } from '../utils/transform';
import { createMessageData } from '../hook/useTransform';
import {
  compareIdAtom,
  compressLoadingAtom,
  compressOptionAtom,
  DefaultCompressOption,
  imageListAtom,
  showOptionAtom,
  tempOptionAtom,
} from './smallerAtoms';
import {
  hasTaskRunningSelector,
  isCropModeSelector,
  progressHintInfoSelector,
} from './smallerSelector';

const useHomeState = () => {
  // const { createMessageData } = useTransform();
  const [list, setList] = useRecoilState(imageListAtom);
  const [option, setOption] = useRecoilState(compressOptionAtom);
  const [compressLoading, setCompressLoading] = useRecoilState(compressLoadingAtom);
  const [tempOption, setTempOption] = useRecoilState(tempOptionAtom);
  const [compareId, setCompareId] = useRecoilState(compareIdAtom);
  const [showOption, setShowOption] = useRecoilState(showOptionAtom);

  const isCropMode = useRecoilValue(isCropModeSelector);
  const hasTaskRunning = useRecoilValue(hasTaskRunningSelector);
  const progressHintInfo = useRecoilValue(progressHintInfoSelector);

  const clear = () => {
    setList(new Map());
    setTempOption({ ...DefaultCompressOption });
    setOption({ ...DefaultCompressOption });
  };

  const reCompress = () => {
    list.forEach((info) => {
      URL.revokeObjectURL(info.compress!.src);
      info.compress = undefined;
      createMessageData(info, option);
    });
  };

  return {
    list,
    setList,
    compareId,
    setCompareId,
    option,
    tempOption,
    showOption,
    setShowOption,
    isCropMode,
    hasTaskRunning,
    progressHintInfo,
    clear,
    reCompress,
    compressLoading,
    setCompressLoading,
  };
};

export default useHomeState;
