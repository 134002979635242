import { CompressOption, ImageItem } from '@/utils/picControler/ImageBase';
import { atom } from 'recoil';
// import { DefaultCompressOption } from '../utils/states';

export const DefaultCompressOption: CompressOption = {
  preview: {
    maxSize: 256,
  },
  resize: {
    method: undefined,
    width: undefined,
    height: undefined,
    short: undefined,
    long: undefined,
    cropWidthRatio: undefined,
    cropHeightRatio: undefined,
    cropWidthSize: undefined,
    cropHeightSize: undefined,
  },
  format: {
    target: undefined,
    transparentFill: '#FFFFFF',
  },
  jpeg: {
    quality: 0.75,
  },
  png: {
    colors: 128,
    dithering: 0.5,
  },
  gif: {
    colors: 128,
    dithering: false,
  },
  avif: {
    quality: 50,
    speed: 8,
  },
};

export const imageListAtom = atom<Map<number, ImageItem>>({
  key: 'imageListAtom',
  default: new Map(),
});

export const compressOptionAtom = atom<CompressOption>({
  key: 'compressOptionAtom',
  default: DefaultCompressOption,
});

export const tempOptionAtom = atom<CompressOption>({
  key: 'tempOptionAtom',
  default: DefaultCompressOption,
});

export const compareIdAtom = atom<number | null>({
  key: 'compareIdAtom',
  default: null,
});

export const showOptionAtom = atom<boolean>({
  key: 'showOptionAtom',
  default: false,
});

export const compressLoadingAtom = atom<boolean>({
  key: 'compressLoadingAtom',
  default: false,
});
