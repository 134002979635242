import { FormItem } from '@/components/BaseForm';
import Label from '@/components/Label';
import Text from '@/components/Text';
import { useLocales } from '@/Locales';
import RecordImgUpload from '@/pages/patient/detail/components/RecordImgUpload';
import TabHeader, { TabHeaderInput } from '@/pages/patient/detail/components/TabHeader';
import { Form, Input } from '@sinohealth/butterfly-ui-components';
import moment from 'moment';
import defaultStyles from '../../index.less';
import OcrFlex from '../OcrFlex';

const AddForm = (props: any) => {
  const { t } = useLocales();

  const mapImage = (urls: string) => {
    return <RecordImgUpload value={urls} disabled />;
  };

  return (
    <OcrFlex>
      <Form name="dynamic_form" layout="vertical" autoComplete="off" {...props}>
        <FormItem
          noStyle
          transformText={(text) => <TabHeader title={moment(text).format('YYYY-MM-DD')} />}
          name="recordTime"
          span={24}
        >
          <TabHeaderInput label={<Text>{t('入院时间')}</Text>} />
        </FormItem>
        <FormItem labelCol={{ span: 24 }} name="imagePath" transformText={mapImage}>
          <RecordImgUpload fileType="medicalRecord" />
        </FormItem>
        <Form.Item name="id" hidden />
        <FormItem labelCol={{ span: 24 }} label={<Label>主诉</Label>} name="chiefComplaint">
          <Input maxLength={500} showCount />
        </FormItem>
        <FormItem
          labelCol={{ span: 24 }}
          className={defaultStyles['speech-form-item']}
          label={<Label>现病史</Label>}
          name="presentIllness"
        >
          <Input.TextArea maxLength={5000} showCount autoSize={{ minRows: 6, maxRows: 6 }} />
        </FormItem>
        <FormItem labelCol={{ span: 24 }} label={<Label>既往史</Label>} name="pastHistory">
          <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
        </FormItem>
        <FormItem
          className={defaultStyles['speech-form-item']}
          labelCol={{ span: 24 }}
          label={<Label>体格检查</Label>}
          name="physicalCheck"
        >
          <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
        </FormItem>
        <FormItem
          className={defaultStyles['speech-form-item']}
          span={24}
          labelCol={{ span: 24 }}
          label={
            <>
              <Label>其他</Label>
              <span style={{ fontSize: '14px', color: '#00000073' }}>
                （{t('可输入过敏史、个人史、月经史、婚育史、家族史')}）
              </span>
            </>
          }
          name="other"
        >
          <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
        </FormItem>
      </Form>
    </OcrFlex>
  );
};

export default AddForm;
