// @ts-ignore
// import WorkerC from './WorkerCompress?worker';
// @ts-ignore
// import WorkerP from './WorkerPreview?worker';
import { uniqId } from '@/utils/picControler/functions';
import { convert, MessageData } from '@/utils/picControler/handler';
import { Dimension, ImageInfo, ImageItem } from '@/utils/picControler/ImageBase';
import Mimes from '@/utils/picControler/mimes';
import _ from 'lodash';
import useHomeState from '../store/useSmaller';

/**
 * @description: 构造图片压缩信息
 * @author: KaifengLi
 * @param {ImageInfo} item
 * @version: v2.25.0
 * @Date: 2024-11-01 15:49:23
 */
export function createMessageData(item: ImageInfo, option?: any): MessageData {
  return {
    /**
     * Why not use the spread operator here?
     * Because it causes an error when used this way,
     * and the exact reason is unknown at the moment.
     *
     * error: `Uncaught (in promise) DOMException: Failed to execute 'postMessage' on 'Worker': #<Object> could not be cloned.`
     * Reproduction method: In the second upload, include the same images as in the first.
     */
    info: {
      key: item.key,
      name: item.name,
      blob: item.blob,
      width: item.width,
      height: item.height,
    },
    // option: toJS(option),
    option: option ? _.cloneDeep(option) : option,
  };
}

function useTransform() {
  const { list, setList, option, setCompressLoading } = useHomeState();

  function createImageList(files: Array<File>, compress?: boolean) {
    const infoListPromise = files.map(async (file) => {
      const info: ImageItem = {
        key: uniqId(),
        name: file.name,
        blob: file,
        width: 0,
        height: 0,
        src: URL.createObjectURL(file),
      };

      // Due to createImageBitmap do not support SVG blob,
      // we should get dimension of SVG via Image
      if (file.type === Mimes.svg) {
        const { width, height } = await new Promise<Dimension>((resolve) => {
          const img = new Image();
          img.src = info.src;
          img.onload = () => {
            resolve({
              x: 0,
              y: 0,
              width: img.width,
              height: img.height,
            });
          };
        });
        info.width = width;
        info.height = height;
      }

      return info;
    });

    if (compress) {
      compressImagePromise(infoListPromise);
    }

    return infoListPromise;
  }

  const compressMessageImage = (value: any, data: any): ImageItem | undefined => {
    if (!value) return undefined;

    const item = _.cloneDeep(value);
    item.width = data.width;
    item.height = data.height;
    item.compress = data.compress ?? item.compress;
    item.preview = data.preview ?? item.preview;

    return item;
  };

  function message(event: any) {
    const value = list.get(event.data.key);
    if (!value) return;

    const item = compressMessageImage(value, event.data) as ImageItem;
    if (!item) return;
    // item.width = event.data.width;
    // item.height = event.data.height;
    // item.compress = event.data.compress ?? item.compress;
    // item.preview = event.data.preview ?? item.preview;

    // SVG can't convert in worker so we do converting here
    // if (item.blob.type === Mimes.svg && event.data.compress) {
    //   await svgConvert(item.compress!);
    // }

    list.set(item.key, item);
  }

  function createMessage(item: ImageInfo): MessageData {
    return {
      /**
       * Why not use the spread operator here?
       * Because it causes an error when used this way,
       * and the exact reason is unknown at the moment.
       *
       * error: `Uncaught (in promise) DOMException: Failed to execute 'postMessage' on 'Worker': #<Object> could not be cloned.`
       * Reproduction method: In the second upload, include the same images as in the first.
       */
      info: {
        key: item.key,
        name: item.name,
        blob: item.blob,
        width: item.width,
        height: item.height,
      },
      option: _.cloneDeep(option),
    };
  }

  const compressImagePromise = async (promise: Array<Promise<ImageItem>>) => {
    const compressPromises = await Promise.all(promise);
    setCompressLoading(true);
    const tasks = compressPromises.map(async (item: ImageItem) => {
      list.set(item.key, item);
      const newlist = createMessage(item);
      const compressOutput = await convert(newlist, 'compress');
      const previewOutput = await convert(newlist, 'preview');
      if (compressOutput) {
        message({
          data: compressOutput,
        });
      }
      if (compressOutput) {
        message({
          data: previewOutput,
        });
      }
    });

    await Promise.all(tasks);
    setCompressLoading(false);
    const updatedList = new Map<number, ImageItem>();
    list.forEach((item) => {
      updatedList.set(item.key, item);
    });
    // console.log(updatedList);
    setList(updatedList);
  };

  return {
    createImageList,
    compressMessageImage,
    compressImagePromise,
  };
}

export default useTransform;
