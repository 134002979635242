/* eslint-disable indent */
import { useLocales } from '@/Locales';

import PatientTag from '@/pages/combinedWard/patientCaseTag/components/PatientTag';
import TimelineCases from '@/pages/combinedWard/timelineLecture/components/TimelineCases';
import { userInfoAtom } from '@/store/atom';
import { Divider, Space } from '@sinohealth/butterfly-ui-components';
import html2canvas from 'html2canvas';
import _ from 'lodash';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PatientInfoStatus } from '../../detail';
import QualityScore from '../QualityScore';
import styles from './index.less';

interface HeaderProps {
  patientInfo?: any;
  score?: boolean;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { patientInfo, score } = props;
  const [params] = useSearchParams();
  const id = params.getAll('id')[0];
  const doctorId = params.getAll('doctorId')[0];
  // 用于测试生成时间轴图片
  const debugGenera = params.getAll('debug')[0] === '1';
  const formType = params.get('type'); // share 共享病例库
  const { t } = useLocales();
  const userInfo = useRecoilValue(userInfoAtom);
  const roleCode = userInfo?.roleCode;
  const employeeId = userInfo?.employee?.id;

  const [patientInfoStatus, setPatientInfoStatus] = useRecoilState(PatientInfoStatus);

  const isShareType = formType === 'share'; // 病例库进来

  const handleMakeSpeech = (type = 'summaryLecture') => {
    const edit = ((doctorId && doctorId === employeeId) || !doctorId) && !isShareType ? '' : 'home';
    const query = `patientId=${patientInfo?.patient?.id}&patientName=${patientInfo?.patient?.name}&type=${type}&edit=${edit}`;
    // 讲稿的共享字段，判断脱敏
    const share = !isShareType ? '' : '&share=share';
    window.open(`${window.location.pathname}#/speech-lecture/lecture?${query}${share}`);
  };

  const handleMakeTimelineSpeech = () => {
    const query = `patientId=${patientInfo?.patient?.id}`;
    window.open(`${window.location.pathname}#/combinedWard/timelineLecture?${query}`);
  };

  const handleGeneraCodeImg = _.debounce(() => {
    if (!debugGenera) return;

    const dom: any = document.querySelector('#timelineCanvas');
    html2canvas(dom, {
      scale: 3, // 可以调整缩放比例
      // backgroundColor: '#fff', // 设置背景色为白色
      useCORS: true, // 如果图片或资源来自不同源，需要启用 CORS
      width: dom.scrollWidth,
      height: dom.scrollHeight,
      windowHeight: dom.scrollHeight * 1.5,
      windowWidth: dom.scrollWidth * 1.5,
    }).then((canvas) => {
      const saveUrl: any = canvas.toDataURL('image/jpg');
      const a: any = document.createElement('a');
      document.body.appendChild(a);
      a.href = saveUrl;
      a.download = `${patientInfo?.patient?.name}_病例时间轴.jpg`;
      a.click();
    });
  }, 500);

  return (
    <>
      <div className={styles.header}>
        <div className={styles['header-container']}>
          <span className={styles.title}> {t('批量上传病例图片')}</span>
          {props?.children}
          <Space split={<Divider type="vertical" />}>
            {patientInfoStatus.edit && (
              <Link
                to={`patientSummary?id=${patientInfo?.patient.id}&patientId=${patientInfo?.patient.id}`}
              >
                <i className="iconfont icon-bianjizhaiyao" /> {t('编辑摘要')}
              </Link>
            )}
            {patientInfoStatus.edit && (
              <Link to={`/combinedWard/timelineLecture?patientId=${patientInfo?.patient?.id}`}>
                <i className="iconfont icon-bianjishijianzhou" /> {t('编辑时间轴')}
              </Link>
            )}
            <a onClick={() => handleMakeSpeech()}>
              <i className="iconfont icon-yulanzhaiyaoxingjianggao" /> {t('预览摘要型讲稿')}
            </a>
            <a onClick={() => handleMakeSpeech('timelineLecture')}>
              <i className="iconfont icon-yulanshijianzhouxingjianggao" /> {t('预览时间轴型讲稿')}
            </a>
            {debugGenera && (
              <a onClick={handleGeneraCodeImg}>
                <i className="iconfont icon-yulanshijianzhouxingjianggao" /> {t('导出时间轴')}
              </a>
            )}
          </Space>
        </div>
      </div>
      {score && !isShareType && (
        <QualityScore style={{ marginBottom: 12, borderBottom: '1px solid #0000001c' }} />
      )}
      <div className={styles.header} style={{ marginBottom: 12, border: 0 }}>
        <div className={styles['header-container']}>
          <span className={styles.title}> {t('病例标签')}</span>
          <div style={{ padding: '12px 0' }}>
            <Space wrap>
              {!isShareType &&
                patientInfo?.zhPatientLabelList?.map((el: any) => (
                  <PatientTag border key={el?.id} color={el?.type === 0 ? 'processing' : 'warning'}>
                    {el?.name}
                  </PatientTag>
                ))}
              {isShareType &&
                patientInfo?.zhPatientLabelList?.map(
                  (el: any) =>
                    el?.type === 0 && (
                      <PatientTag
                        border
                        key={el?.id}
                        color={el?.type === 0 ? 'processing' : 'warning'}
                      >
                        {el?.name}
                      </PatientTag>
                    ),
                )}
              {!patientInfo?.zhPatientLabelList?.length && t('暂无标签')}
              {((doctorId && doctorId === employeeId) || (!doctorId && roleCode === 'doctor')) &&
                !isShareType && (
                  <Link
                    to={`/patient/detail/patientCaseTag?patientId=${id}&labelIds=${patientInfo?.zhPatientLabelList
                      ?.map((el: any) => el?.labelId)
                      .join(',')}`}
                  >
                    <i className="iconfont icon-bianjibiaoqian" /> 编辑标签
                  </Link>
                )}
            </Space>
          </div>
        </div>
      </div>
      {debugGenera && <TimelineCases edit capture />}
    </>
  );
};

export default Header;
