import { Form, FormItem } from '@/components/BaseForm';
import Label from '@/components/Label';
import Text from '@/components/Text';
import TumorStageSelect from '@/components/TumorStageSelect';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import RecordImgUpload from '@/pages/patient/detail/components/RecordImgUpload';
import TabHeader, { TabHeaderInput } from '@/pages/patient/detail/components/TabHeader';
import { addPatientPathology, updatePatientPathology } from '@/services/patient';
import { transformTumorStage } from '@/utils';
import { Form as AntdForm, Input } from '@sinohealth/butterfly-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

const SurgicalRecordsTab = (props: any) => {
  const { data } = props;
  const [form] = AntdForm.useForm();
  const [readOnly, setReadOnly] = useState(false);
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const [patientInfoStatus, setPatientInfoStatus] = useRecoilState(PatientInfoStatus);

  useEffect(() => {
    setReadOnly(!!data?.id);
  }, []);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    return () => {
      // 组件销毁时，如果是编辑状态，把全局的可编辑状态还原
      if (isEditing) {
        setPatientInfoStatus({
          edit: true,
        });
      }
    };
  }, [isEditing]);

  const handleChangeReadOnly = () => {
    const newReadOnly = !readOnly;
    setReadOnly(newReadOnly);
    setPatientInfoStatus({
      edit: newReadOnly,
    });
    setIsEditing(!newReadOnly);
    if (newReadOnly) {
      patientInfo.update();
    }
    form.resetFields();
  };

  const handleCancel = () => {
    patientInfo.update();
    handleChangeReadOnly();
  };

  const handleSubmit = () => {
    form.validateFields().then((formValue) => {
      const { summary, tumorStaging, immunohistochemistry, ...other } = formValue;
      const params = {
        ...other,
        diagnosisDescription: JSON.stringify({ summary, tumorStaging, immunohistochemistry }),
        patientId: patientInfo.patient.id,
      };
      const api = params.id ? updatePatientPathology : addPatientPathology;
      api(params).then(() => {
        patientInfo.update();
        handleChangeReadOnly();
      });
    });
  };
  const action = readOnly ? (
    <a onClick={handleChangeReadOnly}>
      <Text>编辑</Text>
    </a>
  ) : (
    <>
      <a onClick={handleCancel}>
        <Text>取消</Text>
      </a>
      <a onClick={handleSubmit}>
        <Text>保存</Text>
      </a>
    </>
  );
  const mapImage = (urls: string) => {
    return <RecordImgUpload value={urls} disabled />;
  };
  const diagnosisDescription = data?.diagnosisDescription
    ? JSON.parse(data.diagnosisDescription)
    : {};
  const formValue = {
    ...data,
    ...diagnosisDescription,
  };
  return (
    <Form
      className="record-form"
      requiredMark={false}
      form={form}
      readOnly={readOnly}
      initialValues={formValue}
      layout={readOnly ? 'horizontal' : 'vertical'}
    >
      <FormItem name="id" hidden>
        <Input />
      </FormItem>
      <FormItem
        noStyle
        transformText={(text) => (
          <TabHeader
            label={<Text>检查时间</Text>}
            title={moment(text).format('YYYY-MM-DD')}
            action={action}
          />
        )}
        name="recordTime"
        span={24}
      >
        <TabHeaderInput label={<Text>检查时间</Text>} action={action} />
      </FormItem>
      <FormItem name="imagePath" transformText={mapImage}>
        <RecordImgUpload fileType="pathologyReport" />
      </FormItem>
      <FormItem label={<Label>病理概述</Label>} name="summary">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
      <FormItem
        label={<Label>病理分期</Label>}
        name="tumorStaging"
        transformText={(text) => (text ? transformTumorStage(text) : '--')}
      >
        <TumorStageSelect style={{ width: 260 }} />
      </FormItem>
      <FormItem label={<Label>免疫组化</Label>} name="immunohistochemistry">
        <Input maxLength={200} showCount />
      </FormItem>
      <FormItem label={<Label>其他信息</Label>} name="other">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
    </Form>
  );
};

export default SurgicalRecordsTab;
