/* eslint-disable react/no-array-index-key */
import useDict from '@/hooks/dict/useDict';
import { useLocales } from '@/Locales';
import { useCalcParams, useSubmitGptResult } from '@/pages/patient/patientOcrImage/hook/useOcr';
import {
  DrugRecordCheckbox,
  GeneticTestingCheckbox,
  HospitalDischargeRecordsCheckbox,
  ImageReportCheckbox,
  InspectionReportCheckbox,
  MedicalRecordCheckbox,
  OtherInformationCheckbox,
  PathologyReportCheckbox,
  SurgicalRecordCheckbox,
  TABS_MAP,
} from '@/pages/speechLecture/hook/config';
import { handleStartDownloadImg } from '@/pages/speechLecture/hook/makeSpeech';
import SpeechField from '@/pages/speechLecture/lecture/components/SpeechField';
import { formatToDate, transformTumorStage } from '@/utils';
import { inspctionType } from '@/utils/constant';
import { Form, Modal, Radio } from '@sinohealth/butterfly-ui-components';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './index.less';

export const formatField = [
  'recordTime',
  'admitTime',
  'dischargeTime',
  'dischargeTime',
  'startMedicationTime',
  'endMedicationTime',
  'informationDate',
];
export const imgField = ['imagePath', 'reportImage'];
export const specialField = ['imagesType', 'bizId', 'tumorStaging', 'parts'];
export const jsonField = ['content'];
export const inspectionItems = ['type', 'tumorMarkers', 'unit', 'resultValueItem', 'referenceItem'];

interface CompareModelProps {
  compareType?: string;
  onUpdate?: () => void;
}

/**
 * @description: 数据比对
 * @author: KaifengLi
 * @version: v2.27.0
 * @Date: 2024-11-21 14:49:33
 */
const CompareModel = forwardRef((props: CompareModelProps, ref) => {
  const { compareType, onUpdate } = props;
  const [searchParams] = useSearchParams();
  const patientId: string = searchParams.get('patientId') || '';
  const { t } = useLocales();
  const dict = useDict();
  const [form] = Form.useForm();

  const [compareNewDetail, setCompareNewDetail] = useState<any>();
  const [compareOldDetail, setCompareOldDetail] = useState<any>();
  const [open, setOpen] = useState(false);
  const [otherDetail, setOtherDetail] = useState<any>({});

  const { setSubmitParams, submitSuccess, submitLoading } = useSubmitGptResult();

  useImperativeHandle(ref, () => ({
    open: (current?: any, after?: any, other?: any) => {
      other && setOtherDetail(other);
      console.log({ current, after, other });
      if (current && compareType === 'drugRecords') {
        // eslint-disable-next-line no-param-reassign
        delete current?.medicationTime;
      }
      setCompareNewDetail(current);
      setCompareOldDetail(after);
      setOpen(true);
    },
    close: () => setOpen(false),
  }));

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const params: any = useCalcParams(
          values.mergeType,
          compareOldDetail,
          compareNewDetail,
          compareType,
        );
        console.log(params);
        setSubmitParams({
          patientId,
          id: otherDetail?.id,
          recordId: values.mergeType === 3 ? undefined : otherDetail?.recordId,
          jsonValue: JSON.stringify(params),
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (submitSuccess) {
      setOpen(false);
      onUpdate?.();
    }
  }, [submitSuccess]);

  const FieldValue = (item: any, imgKey = 'imagePath', value = {} as any) => {
    if (item?.key === imgKey) {
      return (
        <SpeechField
          color
          border={false}
          type="IMAGE"
          imagesSource={handleStartDownloadImg(value?.[item?.key], true)}
        />
      );
    }

    return <div>{calcFieldValue(item, value)}</div>;
  };

  const calcFieldValue = (item: any, value: any) => {
    if (formatField.includes(item?.key)) {
      return formatToDate(value?.[item?.key]);
    }

    if (item?.key === 'imagesType') {
      return dict?.imageType?.[value?.[item?.key]];
    }

    if (item?.key === 'tumorStaging') {
      return transformTumorStage(value?.[item?.key]);
    }

    if (item?.key === 'useTime') {
      return value?.startMedicationTime && value.endMedicationTime
        ? `${formatToDate(value?.startMedicationTime)} 至 ${formatToDate(value.endMedicationTime)}`
        : '';
    }

    if (item?.key === 'zhInspectionItems') {
      return value?.zhInspectionItems?.map((el: any, index: number) => (
        <div key={`${el?.tumorMarkers}_${index}`} style={{ paddingLeft: 8, paddingBottom: 8 }}>
          <div>
            {t('检验项目')}：{inspctionType[Number(el?.type)]}
          </div>
          <div>
            {t('指标名称')}：{el?.tumorMarkers}
          </div>
          <div>
            {t('单位')}：{el?.unit}
          </div>
          <div>
            {t('结果')}：{el?.resultValueItem}
          </div>
          <div>
            {t('参考区间')}：{el?.referenceItem}
          </div>
        </div>
      ));
    }

    return value?.[item?.key];
  };

  const calcCompareContainer = (value: any) => {
    return (
      <div className={styles.container}>
        {/* 入院记录 */}
        {compareType === 'medicalRecords' &&
          MedicalRecordCheckbox?.map((el) => (
            <div key={el?.key} className={styles['field-item']}>
              <div className={styles['fields-name']}>【{el?.value}】</div>
              {FieldValue(el, 'imagePath', value)}
            </div>
          ))}
        {/* 出院记录 */}
        {compareType === 'hospitalDischargeRecords' &&
          HospitalDischargeRecordsCheckbox?.map((el) => (
            <div key={el?.key} className={styles['field-item']}>
              <div className={styles['fields-name']}>【{el?.value}】</div>
              {FieldValue(el, 'imagePath', value)}
            </div>
          ))}
        {/* 影像报告 */}
        {compareType === 'imageReports' &&
          ImageReportCheckbox?.map(
            (el) =>
              !['zhWedcmTask', 'dicomQrcodeUrl'].includes(el?.key) && (
                <div key={el?.key} className={styles['field-item']}>
                  <div className={styles['fields-name']}>【{el?.value}】</div>
                  {FieldValue(el, 'reportImage', value)}
                </div>
              ),
          )}
        {/* 手术信息 */}
        {compareType === 'surgicalRecords' &&
          SurgicalRecordCheckbox?.map((el) => (
            <div key={el?.key} className={styles['field-item']}>
              <div className={styles['fields-name']}>【{el?.value}】</div>
              {FieldValue(el, 'reportImage', value)}
            </div>
          ))}
        {/* 病理报告 */}
        {compareType === 'pathologyReports' &&
          PathologyReportCheckbox?.map((el) => (
            <div key={el?.key} className={styles['field-item']}>
              <div className={styles['fields-name']}>【{el?.value}】</div>
              {FieldValue(el, 'imagePath', value)}
            </div>
          ))}
        {/* 检验报告 */}
        {compareType === 'inspectionReports' &&
          InspectionReportCheckbox?.map(
            (el) =>
              !inspectionItems.includes(el?.key) && (
                <div key={el?.key} className={styles['field-item']}>
                  <div className={styles['fields-name']}>【{el?.value}】</div>
                  {FieldValue(el, 'imagePath', value)}
                </div>
              ),
          )}
        {/* 基因检测 */}
        {compareType === 'geneticTestings' &&
          GeneticTestingCheckbox?.map((el) => (
            <div key={el?.key} className={styles['field-item']}>
              <div className={styles['fields-name']}>【{el?.value}】</div>
              {FieldValue(el, 'imagePath', value)}
            </div>
          ))}
        {/* 用药记录 */}
        {compareType === 'drugRecords' &&
          DrugRecordCheckbox?.map((el) => (
            <div key={el?.key} className={styles['field-item']}>
              <div className={styles['fields-name']}>【{el?.value}】</div>
              {FieldValue(el, 'imagePath', value)}
            </div>
          ))}
        {/* 参考文献 */}
        {compareType === 'otherInformations' &&
          OtherInformationCheckbox?.map((el) => (
            <div key={el?.key} className={styles['field-item']}>
              <div className={styles['fields-name']}>【{el?.value}】</div>
              {FieldValue(el, 'reportImage', value)}
            </div>
          ))}
      </div>
    );
  };

  return (
    <Modal
      className={styles['compare-model']}
      title={t('操作提示')}
      width={1000}
      style={{ top: '5%' }}
      open={open}
      onOk={handleOk}
      maskClosable={false}
      confirmLoading={submitLoading}
      onCancel={() => setOpen(false)}
    >
      <div className={styles['compare-title']}>
        {t(
          `患者详情中，【${
            compareType && TABS_MAP[compareType]
          }】模块已有本日期病历，请选择内容的添加方式！`,
        )}
      </div>
      <Form name="basic" form={form} autoComplete="off">
        <Form.Item
          label=""
          name="mergeType"
          rules={[{ required: true, message: t('请选择内容的添加方式') }]}
        >
          <Radio.Group>
            <Radio value={1}>合并同一日期，且内容进行新增</Radio>
            <Radio value={2}>合并同一日期，但内容进行覆盖</Radio>
            <Radio value={3}>按新日期内容添加</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
      <div className={styles['detail-container']}>
        <div className={styles['detail-before']}>
          <div className={styles['detail-title']}>{t('本次未添加内容')}</div>
          {calcCompareContainer(compareNewDetail)}
        </div>
        <div className={styles['detail-after']}>
          <div className={styles['detail-title']}>{t('已存在的病历内容')}</div>
          {calcCompareContainer(compareOldDetail)}
        </div>
      </div>
    </Modal>
  );
});

export default CompareModel;
