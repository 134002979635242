/* eslint-disable no-shadow */
import { useLocales } from '@/Locales';
import { useScrollDrag, useZoom } from '@/pages/combinedWard/hook/useDrag';
import { useTimelineDetail } from '@/pages/combinedWard/hook/useTimeline';
import SpeechCard from '@/pages/speechLecture/lecture/components/SpeechCard';
import { timelineHeightAtom } from '@/store/lectureStore';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Empty, Input, Space } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import html2canvas from 'html2canvas';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import GenesForm from '../GenesForm';
import ImageForm from '../ImageForm';
import InspectForm from '../InspectForm';
import OtherForm from '../OtherForm';
import PathologyForm from '../PathologyForm';
import SpeechIssue from '../SpeechIssue';
import TimelineHeader from '../TimelineHeader';
import TimelinePatient from '../TimelinePatient';
import TimelineStep from '../TimelineStep';
import TimelineSubfield from '../TimelineSubfield';
import TreatmentForm from '../TreatmentForm';
import styles from './index.less';

interface TimelineCasesProps {
  edit?: boolean;
  timeline?: boolean; // true 只有时间轴
  capture?: boolean; // true时用于患者详情下载图片
  direction?: 'horizontal' | 'vertical';
}

/**
 * @description: 联合病房-时间轴讲稿-病例时间轴
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-09 10:05:49
 */
const TimelineCases: React.FC<TimelineCasesProps> = (props) => {
  const { direction = 'vertical', capture, timeline, edit = false } = props;
  const { t } = useLocales();

  const [params] = useSearchParams();
  // 用于测试生成时间轴图片
  const debugGenera = params.getAll('debug')[0] === '1';
  const imageFormRef = useRef<any>(null);
  const inspectFormRef = useRef<any>(null);
  const pathologyFormRef = useRef<any>(null);
  const genesFormRef = useRef<any>(null);
  const treatmentFormRef = useRef<any>(null);
  const otherFormFormRef = useRef<any>(null);
  const timelineSubfieldRef = useRef<any>(null);
  const [timelineHeight, setTimelineHeight] = useState<number[]>([]);
  const [lineHeight, setLineHeight] = useRecoilState(timelineHeightAtom);
  const [openTool, setOpenTool] = useState(true);
  const [hasEdit, setHasEdit] = useState(edit);
  const [saving, setSaving] = useState(false);
  const heightStore: number[] = [];

  const { sliderRef, isDragging, handleMouseDown, canDrag, setCanDrag } = useScrollDrag();
  const { timelineDetail, hasTreatmentRecords, setReloadDetail } = useTimelineDetail();
  const { scaleFactor, handleZoom, canvasRef, containerRef } = useZoom(
    timelineHeight.length > 0,
    hasTreatmentRecords,
  );
  // useCenter(sliderRef.current, canvasRef.current);

  /** 生成时间轴图片 */
  const handleGeneraCodeImg = () => {
    if (!debugGenera) return;

    const dom: any = document.querySelector('#timelineCanvas');
    html2canvas(dom, {
      scale: 3, // 可以调整缩放比例
      backgroundColor: '#fff', // 设置背景色为白色
      useCORS: true, // 如果图片或资源来自不同源，需要启用 CORS
      width: dom.scrollWidth,
      height: dom.scrollHeight,
      windowHeight: dom.scrollHeight * 2,
      windowWidth: dom.scrollWidth * 1.5,
    }).then((canvas) => {
      const saveUrl: any = canvas.toDataURL('image/jpg');
      const a: any = document.createElement('a');
      document.body.appendChild(a);
      a.href = saveUrl;
      a.download = `${timelineDetail?.patientInfo?.name}_病例时间轴.jpg`;
      a.click();
    });
  };

  useEffect(() => {
    if (timelineHeight.length) {
      const max = timelineHeight.reduce((acc, curr) => Math.max(acc, curr), -Infinity);
      console.log('max', max);
      setLineHeight(max + 28);
    }
  }, [timelineHeight]);

  return (
    <>
      {timeline && (
        <TimelineHeader
          doctorId={timelineDetail?.patientInfo?.doctorId}
          onEdit={setHasEdit}
          onSave={setSaving}
        />
      )}
      <div
        className={styles.timeline}
        style={{
          padding: !timeline ? 0 : 16,
          position: capture ? 'fixed' : 'static',
          right: 999999,
          display: capture ? 'inline-block' : 'block',
        }}
      >
        {timeline && <TimelinePatient edit={hasEdit} canSave={saving} />}
        {timeline && <SpeechIssue edit={hasEdit} />}
        <SpeechCard
          id="baseInfo"
          title="病例时间轴"
          style={{ marginBottom: 0 }}
          without={!timeline}
        >
          <div className={styles['timeline-cases']}>
            {hasEdit && !capture ? (
              <div className={styles['timeline-tool']} style={{ paddingBottom: openTool ? 16 : 0 }}>
                <div className={styles['tool-title']} onClick={handleGeneraCodeImg}>
                  {t('添加信息')}
                  {/* {openTool ? <UpOutlined /> : <DownOutlined />} */}
                </div>
                <Space size={12} direction={direction}>
                  <ImageForm
                    ref={imageFormRef}
                    button
                    onSubmit={() => {
                      setReloadDetail(true); // 提交后刷新数据
                    }}
                  />
                  <InspectForm
                    ref={inspectFormRef}
                    button
                    onSubmit={() => {
                      setReloadDetail(true); // 提交后刷新数据
                    }}
                  />
                  <PathologyForm
                    ref={pathologyFormRef}
                    button
                    onSubmit={() => {
                      setReloadDetail(true); // 提交后刷新数据
                    }}
                  />
                  <GenesForm
                    button
                    ref={genesFormRef}
                    onSubmit={() => {
                      setReloadDetail(true); // 提交后刷新数据
                    }}
                  />
                  <TreatmentForm
                    button
                    ref={treatmentFormRef}
                    onSubmit={() => {
                      setReloadDetail(true); // 提交后刷新数据
                    }}
                  />
                  <OtherForm
                    button
                    ref={otherFormFormRef}
                    onSubmit={() => {
                      setReloadDetail(true); // 提交后刷新数据
                    }}
                  />
                </Space>
              </div>
            ) : null}
            {!capture && (
              <div className={styles['scale-zoom']}>
                <Space.Compact>
                  <Button
                    type={canDrag ? 'primary' : 'default'}
                    icon={<div className={classNames('iconfont icon-a-shoutuozhuai')} />}
                    onClick={() => setCanDrag(!canDrag)}
                  />
                  <Button icon={<MinusOutlined />} onClick={() => handleZoom('out')} />
                  <Input
                    value={`${Math.round(scaleFactor * 100)}%`}
                    readOnly
                    style={{ width: 60 }}
                  />
                  <Button icon={<PlusOutlined />} onClick={() => handleZoom('in')} />
                </Space.Compact>
              </div>
            )}
            <div
              id="timelineCanvas"
              ref={sliderRef}
              // style={capture ? { position: 'fixed', right: 999999 } : {}}
              className={classNames(
                styles['timeline-canvas'],
                !capture && styles['timeline-canvas-height'],
                hasEdit && !capture && styles['timeline-bgimage'],
              )}
            >
              <div
                className={classNames(styles['timeline-container'])}
                ref={canvasRef}
                style={{
                  transform: `scale(${scaleFactor})`,
                  position: 'relative',
                  paddingTop: capture ? 30 : 0, // 下载图片时添加边距
                  paddingBottom: capture ? 30 : 0, // 下载图片时添加边距
                  paddingLeft: capture ? 30 : 0, // 下载图片时添加边距
                  paddingRight: capture ? 30 : 0, // 下载图片时添加边距
                  zIndex: 1,
                  transformOrigin: 'center', // 确保缩放基于容器中心
                  userSelect: isDragging ? 'none' : 'auto',
                  cursor: isDragging ? 'grabbing' : 'auto',
                }}
                onMouseDown={handleMouseDown}
              >
                <div className="white-space" style={{ height: hasEdit ? 32 : 32, flexShrink: 0 }} />
                <div ref={containerRef} className={styles.container}>
                  {!capture && timelineDetail?.timeLineList.length > 0 && (
                    <div
                      className="white-space"
                      style={{ width: hasEdit ? 300 : 32, flexShrink: 0 }}
                    />
                  )}
                  {timelineDetail?.timeLineList?.map((el: any, index: number) => (
                    <TimelineStep
                      key={el?.dateStr}
                      edit={hasEdit}
                      move={canDrag}
                      timeStepLine={true}
                      dateTime={el?.dateStr}
                      treatmentData={el?.treatmentRecords}
                      imageData={el?.imageReportRecords}
                      inspecData={el?.detectionRecords}
                      otherData={el?.diagnosisRecords}
                      capture={capture}
                      heights={lineHeight}
                      onChangeHeight={(h) => {
                        heightStore.push(h);
                        setTimelineHeight(heightStore);
                      }}
                      onUpdate={(val) => {
                        // console.log('update', val);
                        if (val?.nodeType === 'IMAGE_REPORT') {
                          imageFormRef.current?.open(val);
                        }
                        if (val?.nodeType === 'INSPECTION_REPORT') {
                          inspectFormRef.current?.open(val);
                        }
                        if (val?.nodeType === 'PATHOLOGY_REPORT') {
                          pathologyFormRef.current?.open(val);
                        }
                        if (val?.nodeType === 'GENETIC_TESTING') {
                          genesFormRef.current?.open(val);
                        }
                        const type = [
                          'TREATMENT_RADIOTHERAPY',
                          'TREATMENT_OTHER_INFORMATION',
                          'SURGICAL_RECORD',
                          'DRUG_RECORD',
                        ];
                        if (type.includes(val?.nodeType)) {
                          treatmentFormRef.current?.open(val);
                        }
                        const otherType = ['DIAGNOSIS', 'MDT_CONCLUSION', 'OTHER_INFORMATION'];
                        if (otherType.includes(val?.nodeType)) {
                          otherFormFormRef.current?.open(val);
                        }
                      }}
                      onRela={(i, y) => timelineSubfieldRef.current?.open(i, y)}
                      onDelete={() => {
                        // console.log('delete');
                        setReloadDetail(true);
                      }}
                    />
                  ))}
                  {timelineDetail?.timeLineList.length === 0 && hasEdit && (
                    <Empty style={{ width: '100%', padding: '200px 0' }} />
                  )}
                  {timelineDetail?.timeLineList.length > 0 && (
                    <div
                      className="white-space"
                      style={{ width: !capture && hasEdit ? 300 : 50, flexShrink: 0 }}
                    />
                  )}
                </div>
                {!capture && timelineDetail?.timeLineList.length > 0 && (
                  <div
                    className="white-space"
                    style={{ height: hasEdit ? 200 : 32, flexShrink: 0 }}
                  />
                )}
              </div>
            </div>
          </div>
        </SpeechCard>
      </div>
      <TimelineSubfield ref={timelineSubfieldRef} edit={hasEdit} />
    </>
  );
};

export default TimelineCases;
