import PicSmallerUpload from '@/pages/combinedWard/picSmaller/components/PicSmallerUpload';
import CompressImage from '@/pages/patient/patientOcrImage/components/CompressImage';
import { useProcessFile } from '@/pages/patient/patientOcrImage/hook/useFile';
import React, { useEffect } from 'react';
import styles from './index.less';

interface SingleCompressProps {
  accept?: string;
  fileType?: string;
  onUpdate?: (url?: string) => void;
}

/**
 * @description: 当个压缩上传
 * @author: KaifengLi
 * @version: v2.27.0
 * @Date: 2024-11-21 10:34:42
 */
const SingleCompress: React.FC<SingleCompressProps> = (props) => {
  const { accept, fileType, onUpdate } = props;
  const {
    uploadFiles,
    processDetail,
    processSucc,
    addFileToQueue,
    setProcessDetail,
    removeFileFromQueue,
  } = useProcessFile('report', fileType);

  useEffect(() => {
    if (processDetail && processSucc) {
      onUpdate?.(processDetail?.afterFilePath);
      setProcessDetail(undefined);
    }
  }, [processDetail, processSucc]);

  return (
    <div className={styles['single-compress']}>
      {uploadFiles?.map((el, index) => {
        return (
          <CompressImage
            key={el.file.name}
            progress={-1}
            index={index}
            loading={index === 0}
            picSource={uploadFiles?.map((item) => item.compress?.src) as string[]}
            blob={!!el?.compress}
            url={el?.compress?.src}
            tip={index === 0 ? `图片检测中 ${processDetail?.progress || 0}%` : '图片检测中 0%'}
            onDelete={() => removeFileFromQueue(index)}
          />
        );
      })}
      <PicSmallerUpload
        auto={false}
        accept={accept}
        uploadTip="图片压缩中"
        onChange={(urls, file, filetype?: string) => {
          if (filetype) {
            onUpdate?.(urls[0]);
          } else {
            addFileToQueue(file);
          }
        }}
      />
    </div>
  );
};

export default SingleCompress;
