import Locales from '@/Locales';
import '@/style/iconfont/iconfont.css';
import { Spin } from '@sinohealth/butterfly-ui-components/lib';
import '@sinohealth/butterfly-ui-components/lib/index.css';
import { Suspense } from 'react';
import { AliveScope } from 'react-activation';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import App from './App';

const loading = (
  <div style={{ display: 'flex', height: '100vh' }}>
    <Spin size="large" style={{ margin: 'auto' }} />
  </div>
);
ReactDOM.render(
  <RecoilRoot>
    <Locales>
      <Suspense fallback={loading}>
        <HashRouter>
          <AliveScope>
            <App />
          </AliveScope>
        </HashRouter>
      </Suspense>
    </Locales>
  </RecoilRoot>,
  document.getElementById('root'),
);
