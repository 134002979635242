import { FormItem } from '@/components/BaseForm';
import Label from '@/components/Label';
import { useLocales } from '@/Locales';
import RecordImgUpload from '@/pages/patient/detail/components/RecordImgUpload';
import { useSetImageFlag } from '@/pages/speechLecture/hook/makeSpeech';
import { addPatientMedical, deletePatientMedical, updatePatientMedical } from '@/services/patient';
import { speechFilterEditAtom } from '@/store/speech';
import { Form, Input } from '@sinohealth/butterfly-ui-components';
import _ from 'lodash';
import { memo, useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { CreateFormProps } from '../..';
import defaultStyles from '../../index.less';
import SpeechField from '../SpeechField';
import SpeechStep from '../SpeechStep';
import SpeechStepCard from '../SpeechStepCard';

const CreateForm = memo((props: CreateFormProps) => {
  const { item, index, patientId, firstRender, speechUpdateTime } = props;

  const [form] = Form.useForm();

  const { t } = useLocales();

  const speechFilterEditFlag = useRecoilValue(speechFilterEditAtom);
  const { speechEditImage, handleSetImageEditFlag } = useSetImageFlag();

  const values = Form.useWatch([], form);

  const mapImage = (urls: string) => {
    return <RecordImgUpload value={urls} disabled />;
  };

  useEffect(() => {
    try {
      form.validateFields().then(
        () => {
          if (speechFilterEditFlag && !firstRender) {
            // console.log({ values, firstRender });
            throttledOnFieldsChange(values, speechEditImage);
          }
        },
        () => {},
      );
    } catch (err) {
      console.log(err);
    }
  }, [values, speechEditImage]);

  // 使用 throttle 函数限制 onFieldsChange 的执行频率
  const throttledOnFieldsChange = useCallback(
    _.debounce(
      (val, flag) => {
        if (flag) return;
        if (val?.id) {
          updatePatientMedical(val).then(() => {
            console.log('更新成功');
            props?.onUpdate && props?.onUpdate(val);
          });
        } else {
          addPatientMedical({ ...val, patientId }).then(() => {
            console.log('新增成功');
            props?.onUpdate && props?.onUpdate();
          });
        }
      },
      speechUpdateTime,
      { leading: false },
    ),
    [],
  ); // 3000 milliseconds = 3

  return (
    <Form
      name={`dynamic_form_${index}`}
      form={form}
      key={item?.id}
      layout="vertical"
      initialValues={item}
      autoComplete="off"
    >
      <SpeechStep
        key={item?.id}
        id={`medicalRecord_${item?.id}`}
        title={`${t('入院时间')}：`}
        time={item?.recordTime}
        propKey="recordTime"
        flex
        onChange={(v: string) => {
          form.setFieldValue('recordTime', v);
        }}
        onDelete={() => {
          if (item?.id) {
            deletePatientMedical({
              id: item?.id,
            }).then(() => {
              console.log('删除成功');
              props?.onDelete && props?.onDelete(item.id);
            });
          } else {
            // 新数据删除
            props?.onDelete && props?.onDelete();
          }
        }}
      >
        <div className={defaultStyles['flex-form']}>
          <SpeechStepCard padding="16px 16px 4px 16px">
            <FormItem
              className={defaultStyles['speech-form-item']}
              name="imagePath"
              transformText={mapImage}
            >
              <RecordImgUpload
                disabled={!speechFilterEditFlag}
                onEdit={handleSetImageEditFlag}
                fileType="medicalRecord"
              />
            </FormItem>
          </SpeechStepCard>
          <SpeechStepCard>
            {speechFilterEditFlag ? (
              <>
                <Form.Item name="id" hidden />
                <Form.Item name="recordTime" hidden />
                <FormItem label={<Label>主诉</Label>} name="chiefComplaint">
                  <Input maxLength={500} showCount />
                </FormItem>
                <FormItem
                  className={defaultStyles['speech-form-item']}
                  label={<Label>现病史</Label>}
                  name="presentIllness"
                >
                  <Input.TextArea
                    maxLength={5000}
                    showCount
                    autoSize={{ minRows: 6, maxRows: 6 }}
                  />
                </FormItem>
                <FormItem label={<Label>既往史</Label>} name="pastHistory">
                  <Input.TextArea
                    maxLength={1000}
                    showCount
                    autoSize={{ minRows: 4, maxRows: 4 }}
                  />
                </FormItem>
                <FormItem
                  className={defaultStyles['speech-form-item']}
                  label={<Label>体格检查</Label>}
                  name="physicalCheck"
                >
                  <Input.TextArea
                    maxLength={1000}
                    showCount
                    autoSize={{ minRows: 4, maxRows: 4 }}
                  />
                </FormItem>
                <FormItem
                  className={defaultStyles['speech-form-item']}
                  label={
                    <>
                      <Label>其他</Label>
                      <span style={{ fontSize: '14px', color: '#00000073' }}>
                        （{t('可输入过敏史、个人史、月经史、婚育史、家族史')}）
                      </span>
                    </>
                  }
                  name="other"
                >
                  <Input.TextArea
                    maxLength={1000}
                    showCount
                    autoSize={{ minRows: 4, maxRows: 4 }}
                  />
                </FormItem>
              </>
            ) : (
              <>
                <SpeechField border title="主诉" description={item.chiefComplaint} />
                <SpeechField border title="现病史" description={item.presentIllness} />
                <SpeechField border title="既往史" description={item.pastHistory} />
                <SpeechField border title="体格检查" description={item.physicalCheck} />
                <SpeechField title="其他" border="false" description={item.other} />
              </>
            )}
          </SpeechStepCard>
        </div>
        {props?.ocrContent}
      </SpeechStep>
    </Form>
  );
});

export default CreateForm;
