/* eslint-disable no-restricted-syntax */
import { filesize } from 'filesize';
import { CompressOption, ImageItem } from './ImageBase';
import Mimes from './mimes';
/* eslint-disable no-underscore-dangle */

/**
 * Globaly uniqid in browser session lifecycle
 */
let __UniqIdIndex = 0;
export function uniqId() {
  __UniqIdIndex += 1;
  return __UniqIdIndex;
}

/**
 * Beautify byte size
 * @param num byte size
 * @returns
 */
export function formatSize(num: number) {
  const result = filesize(num, { standard: 'jedec', output: 'array' });
  return `${result[0]} ${result[1]}`;
}

/**
 * Create a download dialog from browser
 * @param name
 * @param blob
 */
export function createDownload(name: string, blob: Blob) {
  const anchor = document.createElement('a');
  anchor.href = URL.createObjectURL(blob);
  anchor.download = name;
  anchor.click();
  anchor.remove();
}

/**
 * Get file suffix by lowercase
 * @param fileName
 */
export function splitFileName(fileName: string) {
  const index = fileName.lastIndexOf('.');
  const name = fileName.substring(0, index);
  const suffix = fileName.substring(index + 1).toLowerCase();
  return { name, suffix };
}

export function getOutputFileName(item: ImageItem, option: CompressOption) {
  if (item.blob.type === item.compress?.blob.type) {
    return item.name;
  }

  const { name, suffix } = splitFileName(item.name);
  let resultSuffix = suffix;
  for (const key in Mimes) {
    if (item.compress!.blob.type === Mimes[key]) {
      resultSuffix = key;
      break;
    }
  }

  if (['jpg', 'jpeg'].includes(resultSuffix)) {
    resultSuffix = option.format.target?.toLowerCase() || resultSuffix;
  }

  return `${name}.${resultSuffix}`;
}

/**
 * If names Set already has name, add suffix '(1)' for the name
 * which will newly pushed to names set
 *
 * @param names will checked names Set
 * @param name will pushed to names
 */
export function getUniqNameOnNames(names: Set<string>, name: string): string {
  const getName = (checkName: string): string => {
    if (names.has(checkName)) {
      const nameParts = checkName.split('.');
      const extension = nameParts.pop();
      const newName = `${nameParts.join('')}(1).${extension}`;
      return getName(newName);
    }
    return checkName;
  };
  return getName(name);
}
