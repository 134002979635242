/* eslint-disable indent */
import DateInput from '@/components/DateInput';
import Label from '@/components/Label';
import { useLocales } from '@/Locales';
import { useSubmitGptResult } from '@/pages/patient/patientOcrImage/hook/useOcr';
import { httpPatientRepeatRecord } from '@/services/imageProcess';
import { updatePatientMedicalOcrItem } from '@/services/patient';
import { Badge, Form, Popconfirm, Space, message } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import lodash from 'lodash';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CompareModel from '../CompareModel';
import styles from './index.less';

export const dateConfig: any = {
  medicalRecords: {
    label: '入院时间',
    key: 'recordTime',
  },
  hospitalDischargeRecords: {
    label: '出院时间',
    key: 'dischargeTime',
  },
  imageReports: {
    label: '检查时间',
    key: 'recordTime',
  },
  surgicalRecords: {
    label: '手术时间',
    key: 'recordTime',
  },
  pathologyReports: {
    label: '检查时间',
    key: 'recordTime',
  },
  inspectionReports: {
    label: '检查时间',
    key: 'recordTime',
  },
  geneticTestings: {
    label: '检查时间',
    key: 'recordTime',
  },
  drugRecords: {
    label: '医嘱时间',
    key: 'recordTime',
  },
  otherInformations: {
    label: '资料时间',
    key: 'informationDate',
  },
};
const RecordItemBox = (props: any, ref: any) => {
  const { formContent, type, value, onValuesChange, onDelete, onUpdate } = props;
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const patientId: string = searchParams.get('patientId') || '';
  const { t } = useLocales();
  const [recordValue, setRecordValue] = useState(value);
  const [updateLoading, setUpdateLoading] = useState(false);
  // const { setRepeatParams, compareOldDetail, otherDetail, setLoadRepeatSucc, loadRepeatSucc } =
  //   useRepeatRecord();
  const { setSubmitParams, submitSuccess, submitLoading } = useSubmitGptResult();

  const compareModelRef = useRef<any>();

  useImperativeHandle(
    ref,
    () => {
      return {
        form,
      };
    },
    [form],
  );

  const handleDelete = () => {
    onDelete && onDelete(value.bizId, type);
  };

  const handleCompareCase = () => {
    if (submitLoading || updateLoading) {
      message.warning('数据在保存中，请稍后。');
      return;
    }
    form
      .validateFields()
      .then(() => {
        // setRepeatParams({
        //   patientId,
        //   id: recordValue?.bizId,
        //   jsonValue: JSON.stringify(recordValue),
        // });
        httpPatientRepeatRecord({
          patientId,
          id: recordValue?.bizId,
          jsonValue: JSON.stringify(recordValue),
        }).then((res: any) => {
          const { jsonValue, ...etc } = res || {};
          if (jsonValue) {
            compareModelRef.current?.open(recordValue, JSON.parse(jsonValue), etc);
          } else {
            console.log('没有旧数据，直接新增');
            setSubmitParams({
              patientId,
              id: recordValue?.bizId,
              recordId: undefined,
              jsonValue: JSON.stringify(recordValue),
            });
          }
        });
        // compareModelRef.current?.open(recordValue, recordValue);
      })
      .catch(() => {});
  };

  // useEffect(() => {
  //   if (!loadRepeatSucc || !updateSucc) return;
  //   if (compareOldDetail) {
  //     compareModelRef.current?.open(recordValue, compareOldDetail, otherDetail);
  //   } else {
  //     console.log('没有旧数据，直接新增');
  //     setSubmitParams({
  //       patientId,
  //       id: recordValue?.bizId,
  //       recordId: undefined,
  //       jsonValue: JSON.stringify(recordValue),
  //     });
  //   }
  //   setLoadRepeatSucc(false);
  // }, [compareOldDetail, loadRepeatSucc, updateSucc]);

  useEffect(() => {
    if (submitSuccess) {
      onUpdate && onUpdate();
    }
  }, [submitSuccess]);

  const handleValuesChange = (changeValue: any, formValue: any) => {
    const data = {
      formValue: {
        ...value,
        ...formValue,
      },
      type,
    };
    onValuesChange && onValuesChange(data);
    handleSubmit(data);
  };

  const handleSubmit = useCallback(
    lodash.debounce((data: any) => {
      const formValue = { ...data.formValue };
      if (type === 'drugRecords' && formValue.medicationTime?.length > 0) {
        formValue.startMedicationTime = formValue.medicationTime[0];
        formValue.endMedicationTime = formValue.medicationTime[1];
      }

      setUpdateLoading(true);
      setRecordValue(lodash.cloneDeep(formValue));
      updatePatientMedicalOcrItem({
        id: data.formValue.bizId,
        jsonValue: JSON.stringify(formValue),
      })
        .then(() => {
          setUpdateLoading(false);
          message.success(t('自动保存成功'));
        })
        .catch(() => {})
        .finally(() => {
          setUpdateLoading(false);
        });
    }, 1500),
    [],
  );

  const initialValues =
    type === 'drugRecords'
      ? {
          ...value,
          // 用药时间字段特殊处理
          medicationTime:
            value.startMedicationTime && value.endMedicationTime
              ? [value.startMedicationTime, value.endMedicationTime].map(dayjs)
              : null,
        }
      : value;

  return (
    <div className={styles.recordItemBox}>
      <Form
        layout="vertical"
        form={form}
        validateTrigger="onSubmit"
        initialValues={initialValues}
        onValuesChange={handleValuesChange}
      >
        <div className={styles.header}>
          <Badge status="processing" />
          <span className={styles.label}>
            <span style={{ color: '#ff4d4f', marginRight: 4, fontFamily: 'SimSun,sans-serif' }}>
              *
            </span>
            {t(dateConfig[type].label)}
          </span>
          <Form.Item
            name={dateConfig[type].key}
            rules={[{ required: true, message: t('该字段为必填项') }]}
          >
            <DateInput style={{ width: 320 }} />
          </Form.Item>
          <Space style={{ marginLeft: 'auto' }}>
            <a className={styles.action} onClick={handleCompareCase}>
              {t('添加至病历')}
            </a>
            <Popconfirm
              title={t('删除该条识别内容？')}
              placement="left"
              onConfirm={handleDelete}
              okButtonProps={{ danger: true }}
              okText={t('确定')}
              cancelText={t('取消')}
            >
              <a className={styles.action}>{t('删除')}</a>
            </Popconfirm>
          </Space>
        </div>
        <div className={styles.body}>
          <div className={styles.left}>{formContent}</div>
          <div className={styles.right}>
            <Label>OCR识别结果</Label>
            <div className={styles.content}>{value?.content || t('无')}</div>
          </div>
        </div>
      </Form>
      <CompareModel ref={compareModelRef} compareType={type} onUpdate={onUpdate} />
    </div>
  );
};

export default React.forwardRef(RecordItemBox);
